<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.title" :rules="$rules.required" label="Setup title" required />
          <v-select
            v-if="create"
            v-model="local.copyfrom"
            :items="copysetups"
            label="Copy from Setup"
            item-text="name"
            item-value="id"
            :disabled="!copysetups.length"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea v-model="local.notes" label="Notes" />
        </v-col>
      </v-row>

      <v-row v-if="!noSubmit">
        <v-col cols="12">
          <v-btn color="primary" @click="submit">
            <slot />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'
import { DateTime } from 'luxon'

import { CopyableSetups } from '@graphql/Setups.gql'

export default {
  name: 'SetupInfoForm',
  apollo: {
    copysetups: {
      query: CopyableSetups,
      update: (data) => {
        const runsetups = data.testingRuns.map((run) => ({
          id: run.setup.id,
          name: `Run ${run.number} from ${run.day.title} (${DateTime.fromISO(run.day.date).toFormat('dd LLL yyyy')})`,
          date: run.day.date
        }))
        const basesetups = data.baseSetups.map((base) => ({
          id: base.setup.id,
          name: `Base Setup ${base.title} (${DateTime.fromISO(base.createdAt).toFormat('dd LLL yyyy')})`,
          date: base.createdAt
        }))
        const copysetups = runsetups.concat(basesetups)
        copysetups.sort((a, b) => new Date(b.date) - new Date(a.date))
        return copysetups
      }
    }
  },
  props: {
    setup: {
      type: Object,
      default() {
        return {
          title: '',
          notes: ''
        }
      }
    },
    create: { type: Boolean, default: false },
    noSubmit: Boolean
  },
  data: () => ({
    copysetups: [],
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.setup)
    if (this.create) this.local.copyfrom = ''
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        title: this.local.title,
        notes: this.local.notes,
        copyfrom: this.local.copyfrom
      }
      this.$emit('submit', data)
      return data
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
