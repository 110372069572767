<template>
  <v-container>
    <car-selection v-model="selectedCar" :cars="cars"></car-selection>
    <v-divider class="mt-6 mb-6"></v-divider>

    <v-btn v-if="selectedCar" block color="primary" elevation="2" @click="showCreateSetupSheet = true">
      Create a new setup for "{{ selectedCar.name }}"
    </v-btn>
    <v-row class="align-stretch justify-stretch mt-6">
      <v-col cols="12" md="6" lg="4" v-for="setup in setups" :key="setup.id">
        <v-skeleton-loader v-if="setup.id === '-1'" type="card"></v-skeleton-loader>
        <setup-menu-card v-else :setup="setup" />
      </v-col>
    </v-row>

    <v-bottom-sheet v-model="showCreateSetupSheet" scrollable>
      <v-card>
        <v-card-title>Create a new Setup</v-card-title>
        <v-card-text>
          <setup-info-form @submit="createSetup" create>Create</setup-info-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import CarSelection from '@components/cars/Selection.vue'
import SetupMenuCard from '@components/setup/MenuCard.vue'
import SetupInfoForm from '@components/setup/InfoForm.vue'
import { CarInfo } from '@graphql/Cars.gql'
import { BaseSetupOverviews, CreateBaseSetup, CopyFromSetup } from '@graphql/Setups.gql'

export default {
  name: 'SetupsPage',
  components: { CarSelection, SetupMenuCard, SetupInfoForm },
  apollo: {
    cars: {
      query: CarInfo,
      update: (data) => {
        const cars = data.cars
        cars.sort((a, b) => b.season - a.season)
        return cars
      }
    },
    setups: {
      query: BaseSetupOverviews,
      variables() {
        return { where: { carId: { equals: this.selectedCar.id } } }
      },
      skip() {
        return !this.selectedCar
      },
      update: (data) => {
        const setups = data.baseSetups
        setups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        return setups
      }
    }
  },
  data: () => ({
    cars: [],
    setups: [],
    selectedCar: undefined,
    showCreateSetupSheet: false
  }),
  methods: {
    createSetup(data) {
      const newSetup = {
        id: '-1',
        ...data
      }
      this.setups.push(newSetup)
      const variables = {
        data: {
          title: newSetup.title,
          notes: newSetup.notes,
          carId: this.selectedCar.id
        }
      }
      this.$apollo
        .mutate({ mutation: CreateBaseSetup, variables })
        .then(({ data: { createBaseSetup } }) => {
          if (!data.copyfrom) {
            Object.assign(newSetup, createBaseSetup)
            this.redirectToSetupFlow(createBaseSetup.id)
            return
          }
          // Copy
          const variables = {
            copyfrom: data.copyfrom,
            where: {
              id: createBaseSetup.id
            }
          }
          this.$apollo
            .mutate({ mutation: CopyFromSetup, variables })
            .then(({ data: { copyFromSetup } }) => {
              Object.assign(newSetup, copyFromSetup)
              this.redirectToSetupFlow(copyFromSetup.id)
            })
            .catch(this.showError)
        })
        .catch(this.showError)
    },
    redirectToSetupFlow(id) {
      this.$router.push({ name: 'SetupFlow', params: { id }, hash: '#setup-tools' })
      this.showCreateSetupSheet = false
    },
    showError(res) {
      const setupIdx = this.setups.findIndex((s) => s.id === '-1')
      this.setups.splice(setupIdx, 1)
      this.$store.commit('openErrorDialog', res)
    }
  }
}
</script>
